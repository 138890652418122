<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :editable="editable"
            label="연료명"
            name="title"
            v-model="searchParam.title">
          </c-text>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            :comboItems="useFlagItems"
            label="LBLUSEFLAG"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            type="search"
            v-model="searchParam.useFlag">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <c-table
          ref="table"
          title="약품 목록"
          tableId="table"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :columnSetting="false"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <q-form ref="editForm">
          <c-card title="LBLDETAIL" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="addData" />
                <c-btn
                  v-if="editable && saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <c-plant 
                  :required="true"
                  :editable="editable && dataeditable" 
                  type="edit" 
                  name="plantCd" 
                  v-model="data.plantCd" />
              </div>
              <div class="col-6">
                <c-select
                  :editable="editable && dataeditable"
                  codeGroupCd="AIR_DRUG_UNIT_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="airDrugUnitCd"
                  label="LBLUNIT"
                  v-model="data.airDrugUnitCd"
                ></c-select>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="약품명"
                  name="envAirMstDrugName"
                  v-model="data.envAirMstDrugName">
                </c-text>
              </div>
              <div class="col-6">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="LBLUSEFLAG"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'air-master-06',
  props: {
    param: {
      type: Object,
      default: () => ({
      }),
    },
  },
  data() {
    return {
      data: {
        plantCd: null,  // 사업장코드
        envAirMstDrugId: '',  // 대기약품 일련번호
        envAirMstDrugName: '',  // 대기약품명
        airDrugUnitCd: null,  // 대기약품 단위
        useFlag: 'Y',  // 사용여부
      },
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      listUrl: '',
      detailUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.env.air.mst.drug.insert.url,
      saveType: 'POST',
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
          {
            name: 'envAirMstDrugName',
            field: 'envAirMstDrugName',
            label: '약품명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'airDrugUnitName',
            field: 'airDrugUnitName',
            label: 'LBLUNIT',
            align: 'center',
            style: 'width: 150px',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
        ],
        data: [],
      },
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;
      // api scope
      this.listUrl = selectConfig.env.air.mst.drug.list.url;
      this.detailUrl = selectConfig.env.air.mst.drug.get.url;
      this.insertUrl = transactionConfig.env.air.mst.drug.insert.url;
      this.updateUrl = transactionConfig.env.air.mst.drug.update.url;
      this.deleteUrl = transactionConfig.env.air.mst.drug.delete.url;
      this.getDataList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getDataList() {
      this.getList();
      this.rowRemoveSelect();
      this.reset();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      this.saveable = false;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.updateMode = true;
      this.$http.url = this.detailUrl;
      this.$http.param = {envAirMstDrugId: row.envAirMstDrugId}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    addData() {
      this.saveable = true;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        plantCd: this.searchParam.plantCd,  // 사업장코드
        envAirMstDrugId: '',  // 대기약품 일련번호
        envAirMstDrugName: '',  // 대기약품명
        airDrugUnitCd: null,  // 대기약품 단위
        useFlag: 'Y',  // 사용여부
      };
      this.rowRemoveSelect();
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        plantCd: null,  // 사업장코드
        envAirMstDrugId: '',  // 대기약품 일련번호
        envAirMstDrugName: '',  // 대기약품명
        airDrugUnitCd: null,  // 대기약품 단위
        useFlag: 'Y',  // 사용여부
      };
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId
              this.data.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDataList();
    },
  }
};
</script>
